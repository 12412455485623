import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import Title from "../components/title"
import Layout from "../components/layout"

const PageMethods = props => {
  const methods = props.data.posts.edges.map(({ node }) => ({
    link: node.link,
    id: node.wordpress_id,
    title: node.title,
  }))

  return (
    <Layout>
      <SEO title="Metody" />
      <Title>Metody</Title>
      <Methods>
        {methods.map(({ id, link, title }) => (
          <Method key={id}>
            <StyledLink to={link}>{title}</StyledLink>
          </Method>
        ))}
      </Methods>
    </Layout>
  )
}

const Methods = styled.ul`
  padding: 0;
  list-style: disc;
  margin: 10px 20px;
`

const Method = styled.li`
  line-height: 2;
  font-size: 1.75rem;
`

const StyledLink = styled(Link)`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.secondary};

  &:hover,
  &:hover:active {
    text-decoration: underline;
    color: ${({ theme }) => theme.secondary};
  }
`

export default PageMethods

export const PageMethodsQuery = graphql`
  {
    posts: allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "metody" } } } }
    ) {
      edges {
        node {
          title
          link
          wordpress_id
          excerpt
          content
        }
      }
    }
  }
`
